import * as TradingViewApi from 'api/trading';
import _ from 'lodash';

const history = {};

// this maps every possible interval key to chart resolution string accepted by tradingview
const interval = {
  1: '1',
  3: '3',
  5: '5',
  15: '15',
  30: '30',
  60: '60',
  '1h': '60',
  120: '120',
  '2h': '120',
  240: '240',
  '4h': '240',
  360: '360',
  '6h': '360',
  480: '480',
  '8h': '480',
  720: '720',
  '12h': '720',
  1440: '1d',
  '24h': '1d',
  '1d': '1d',
  '1D': '1d',
  '3D': '3d',
  '3d': '3d',
  W: '1w',
  '1W': '1w',
  M: '1M',
  '1M': '1M',
}

export default {
  history: history,

  getBars: function (symbolInfo, resolution, from, to, first, limit) {
    let totalKlines = [];
    const chartResolution = interval[window.getPeriod(symbolInfo)]
    const getKlines = (from, to) => {
      return TradingViewApi.klines(symbolInfo.exchange, symbolInfo.pair, chartResolution, from, to, limit).then(klines => {
        totalKlines.push(...klines);
        if (klines.length >= limit) {
          from = klines[klines.length - 1][0] + 1;
          return getKlines(from, to);
        } else {
          return totalKlines.map(kline => {
            return {
              time: kline[0],
              close: parseFloat(kline[4]),
              open: parseFloat(kline[1]),
              high: parseFloat(kline[2]),
              low: parseFloat(kline[3]),
              volume: parseFloat(kline[5]),
            };
          });
        }
      });
    };
    return getKlines(from, to).then(bars => {
      if (first && bars.length > 0) {
        const lastBar = bars[bars.length - 1];
        history[symbolInfo.name] = { lastBar: lastBar };
      }
      return bars;
    });
  },
  getTimescaleMarks: async function () {
    if (window.shouldUseMarks === false) {
      return;
    }
    if (!window.tradingView.timescaleMarks) {
      return;
    }
    const { symbolInfo, startDate, endDate, onDataCallback, resolution } = window.tradingView.timescaleMarks;
    const chartResolution = interval[window.getPeriod(symbolInfo)]
    return TradingViewApi.marks(symbolInfo.exchange, symbolInfo.pair, chartResolution, startDate, endDate )
      .then(mark => {
        if (window.shouldUseMarks === false) {
          return []
        }
        return mark.map(t => {
          return {
            id: t['id'],
            time: t['time'],
            color: t['color'],
            label: t['label'],
            minSize: t['minSize'],
            tooltip: t['tooltip'],
          };
        });
      })
      .then(marks => {
        if (marks.length) {
          const debouncedMarks = _.debounce((marks) => {
            window.tvWidget && window.tvWidget.activeChart().clearMarks()
            onDataCallback(marks);
          }, 500)
          debouncedMarks(marks)
        }
      });
  },
};
